<template>
  <div
    v-if="stars"
    class="simple-trustpilot"
  >
    <zg-trustpilot
      :stars="stars"
    />
    <p v-html="template" />
  </div>
</template>

<script>
  export default {
    name: 'Trustpilot',
    components: {
      ZgTrustpilot: () => import(/* webpackChunkName: 'zc/zg-slider-with-input' */
        '@zc/components/ZgTrustpilot/ZgTrustpilot')
    },
    props: {
      stars: {
        type: Number,
        default: undefined
      },
      template: {
        type: String,
        default: ''
      }
    }
  }
</script>

<style lang="scss" scoped>
.simple-trustpilot {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: rhythm(large);

  p {
    @include type-body-m;

    color: color(gray-500);
    padding-top: rhythm(xsmall);
  }
}
</style>
