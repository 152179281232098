<template>
  <div
    v-if="show"
    class="navigation-buttons"
  >
    <zg-button
      id="cl-form-cta"
      key="apply"
      :class="{ 'submit--invalid': !validFields }"
      :disabled="disabled"
      :full-width="onCampaignPage"
      :icons="{ right: 'arrow-forward' }"
      :size="onCampaignPage ? 'large' : 'xlarge'"
      data-name="apply-btn"
      full-width-mobile
      type="button"
      @click="next"
    >
      {{ submitLabel }}
    </zg-button>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'Buttons',
    components: {
      ZgButton: () => import(/* webpackChunkName: 'zc/zg-button' */
        '@zc/components/ZgButton/ZgButton')
    },
    props: {
      submitLabel: {
        type: String,
        required: false,
        default: null
      },
      onCampaignPage: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    computed: {
      ...mapGetters('form', ['getForm']),
      ...mapGetters('cl/formSteps', ['currentStep', 'currentStepName', 'isLastStep']),
      ...mapGetters('cl/formAlternatives', ['getFormAlternatives']),
      ...mapGetters('cl/application', ['getApplicationEvents']),
      show () {
        return this.currentStepName !== 'thankYou'
      },
      disabled () {
        return this.getApplicationEvents?.finalizeApplication
      },
      validFields () {
        return this.currentStep <= 1 || this.getForm?.visibleFieldsAreValid
      }
    },
    methods: {
      ...mapActions('cl/analytics', ['buttonTracking']),
      async next () {
        await this.EXPERIMENTS.track('loan-application-cta-click')
        await this.buttonTracking({ label: this.isLastStep ? 'submit-full-form' : 'continue-form' })

        this.$emit('submit')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .navigation-buttons {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-start;
    margin: 0 0 rhythm(small);

    &--sticky {
      @include mq($until: medium) {
        position: fixed;
        bottom: -100%;
        left: 0;
        right: 0;
        background: color(white);
        box-shadow: 0 -2px 2px 0 rgb(0 0 0 / 10%);
        padding: rhythm(small);
        margin: 0;
        z-index: 100;
        opacity: 0;
        transition: all 0.3s ease;
      }

      &-show {
        @include mq($until: medium) {
          opacity: 1;
          bottom: 0;
        }
      }
    }
  }
</style>
