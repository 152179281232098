<template>
  <div class="sticky-cta">
    <zg-transition-slide-in direction="bottom">
      <zg-sticky-cta
        v-if="show"
        :label="label"
        @click="onClick"
      />
    </zg-transition-slide-in>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { hasProp } from '@ocp-zmarta/zmarta-cl'
  export default {
    name: 'StickyCta',
    components: {
      ZgStickyCta: () => import(/* webpackChunkName: 'zc/zg-sticky-cta' */
        '@zc/components/ZgStickyCta/ZgStickyCta'),
      ZgTransitionSlideIn: () => import(/* webpackChunkName: 'zc/zg-transition-slide-in' */
        '@zc/components/ZgTransitionSlideIn/ZgTransitionSlideIn')
    },
    props: {
      label: {
        type: String,
        required: true
      },
      target: {
        type: String,
        required: true
      }
    },
    data: () => ({
      show: false
    }),
    computed: {
      ...mapGetters('misc', ['getScroll']),
      ...mapGetters('router', ['getQuery']),

      onArticlePage () {
        return hasProp(this.getQuery, 'on-article-page')
      },
      onPartnerPage () {
        return hasProp(this.getQuery, 'on-partner-page')
      },
      hideStickyCta () {
        return this.onPartnerPage || this.onArticlePage
      }
    },
    watch: {
      getScroll () {
        this.isTargetInViewport()
      }
    },
    methods: {
      isTargetInViewport () {
        if (!this.target || this.hideStickyCta) return

        const el = document.querySelector(this.target)
        const elHeight = el.clientHeight

        this.show = this.getScroll >= elHeight
      },
      onClick () {
        this.ZGA.event?.navigation?.click?.({
          category: 'sticky-cta',
          label: 'sticky-cta-compare-loans'
        })
      }
    }
  }
</script>
