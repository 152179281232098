<template>
  <simple-trustpilot
    v-if="getTrustPilotStars"
    :stars="getTrustPilotStars"
    :template="getTrustPilotTemplate"
  />
</template>

<script>
  import { hasProp, secureHtmlString } from '@ocp-zmarta/zmarta-cl'
  import { mapGetters } from 'vuex'
  import SimpleTrustpilot from './SimpleTrustpilot'

  export default {
    name: 'Trustpilot',
    components: {
      SimpleTrustpilot
    },
    computed: {
      ...mapGetters('router', ['getRoute', 'getQuery']),

      getTrustPilotStars () {
        return hasProp(this.getQuery, 'trust_stars') ? Number(this.getQuery.trust_stars) : undefined
      },
      getTrustPilotTemplate () {
        return hasProp(this.getQuery, 'trust_template') ? secureHtmlString(this.getQuery.trust_template, ['strong', 'b'], {}) : ''
      }
    }
  }
</script>
