<template>
  <div
    class="language-translation"
    @click="selectLanguage"
  >
    <zg-image
      :alt="newLocale"
      :src="imgUrl"
      :height="20"
      :width="20"
    />

    <p v-html="content.text" />

    <zg-icon
      icon="arrow-forward"
      size="small"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'LanguageTranslation',
    components: {
      ZgIcon: () => import(/* webpackChunkName: 'zc/zg-icon' */
        '@zc/components/ZgIcon/ZgIcon'),
      ZgImage: () => import(/* webpackChunkName: 'zc/zg-image' */
        '@zc/components/ZgImage/ZgImage')
    },
    computed: {
      ...mapGetters('content', ['getContent']),
      ...mapGetters('translations', ['getLocale']),
      content () {
        return this.getContent?.elements?.languageTranslation ?? {}
      },
      newLocale () {
        return this.getLocale === 'en'
          ? 'sv'
          : 'en'
      },
      imgUrl () {
        return `${this.IMG_SRC}/lang/${this.newLocale}.svg`
      }
    },
    methods: {
      ...mapActions('translations', ['setLocale']),
      selectLanguage () {
        const locale = this.newLocale

        this.trackNavigationClick(`language/${locale}`)
        this.setLocale({ locale })
      },
      trackNavigationClick (label) {
        this.ZGA.event?.navigation?.click?.({
          category: 'change-language',
          label: label?.replace(/^\//, '')
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .language-translation {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: rem(16px);
    min-height: rem(80px);
    background-color: color(beige);
    padding: rem(16px);
    margin: 0 0 rem(32px);
    border-radius: rem(9px);
    cursor: pointer;

    :deep(picture) {
      width: rem(20px);
      height: rem(20px);
    }

    p {
      @include type-body-s;

      flex-grow: 1;
      font-weight: font-weight(bold);
      margin: 0;

      @include mq(medium) {
        @include type-body-m;

        font-weight: font-weight(bold);
        margin: 0;
      }

      :deep(span.break) {
        @include mq(medium) {
          display: block;
        }
      }
    }
  }
</style>
