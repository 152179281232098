var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "language-translation", on: { click: _vm.selectLanguage } },
    [
      _c("zg-image", {
        attrs: { alt: _vm.newLocale, src: _vm.imgUrl, height: 20, width: 20 },
      }),
      _vm._v(" "),
      _c("p", { domProps: { innerHTML: _vm._s(_vm.content.text) } }),
      _vm._v(" "),
      _c("zg-icon", { attrs: { icon: "arrow-forward", size: "small" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }